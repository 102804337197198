import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';

import { styled } from '@mui/material/styles';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SmallButton from '../../components/layout/atoms/SmallButton';
import IconHeading from '../../components/layout/molecules/IconHeading';
import Loading from '../../components/layout/organisms/Loading/Loading';

import { updatePatientMedicalHistory } from '../../actions/healthData';
import { datePickerLangText } from '../../i18n';
import { convertToCustomDateString } from '../../utils/date';
import { HeartIcon, FileTextIcon, XIcon, EditIcon } from '../../resources/icons';

import {
  NO_DIABETES,
  TYPE_1_DIABETES,
  TYPE_2_DIABETES,
  GESTATIONAL_DIABETES,
  DIABETES_TYPE_UNKNOWN,
  NO_DISEASE,
  MILD,
  MODERATE,
  SEVERE,
  PDR,
  ABSENT,
  PRESENT,
} from '../../constants/medicalRecords';

const PREFIX = 'MedicalHistory';

const classes = {
  form: `${PREFIX}-form`,
  formFields: `${PREFIX}-formFields`,
  submit: `${PREFIX}-submit`,
  datePicker: `${PREFIX}-datePicker`,
};

const Root = styled('form', {
  shouldForwardProp(propName) {
    return ['disallowEdit'].indexOf(propName) === -1;
  },
})(({ theme, disallowEdit }) => ({
  height: 420,
  [`& .${classes.formFields}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.submit}`]: {
    marginTop: '20px',
  },

  [`& .${classes.datePicker}`]: {
    margin: '0',
  },
}));

const MedicalHistory = (props) => {
  const { t, i18n } = useTranslation();
  const staticWords = useSelector((state) => state.handlingTranslation.words);

  const initialState = {
    diabetes_type: { values: '' },
    diabetes_onset: { values: null },
    eye_surgery_history: { values: '-' },
    heart_disease: { values: '-' },
    high_blood_pressure: { values: '-' },
    high_cholesterol: { values: '-' },
    dr_presence: { values: '-' },
    me_presence: { values: '-' },
  };

  const [values, setValues] = useState(initialState);
  const [showButtonLoading, setShowButtonLoading] = useState(false);
  const [isHistoryDisabled, setIsHistoryDisabled] = useState(true);
  const promiseInProgress = usePromiseTracker({ area: 'health-data-area' });

  useEffect(() => {
    if (!_.isEmpty(props.medHistory)) {
      const newValues = filterInputsNullToEmptyString(props.medHistory);
      setValues(newValues);
    }
  }, [props.medHistory]);

  // ensure that empty data is not sent to server
  const filterInputs = (rawInputs) => {
    let filteredInputs = {};
    if (typeof rawInputs === 'object' && !_.isEmpty(rawInputs)) {
      Object.entries(rawInputs).map(([fieldName, field]) => {
        if (![null, undefined, '', '-', props.medHistory?.[fieldName]?.values].includes(field?.values)) {
          filteredInputs[fieldName] = field.values;
        }
      });
    }

    return filteredInputs;
  };

  const filterInputsNullToEmptyString = (rawInputs) => {
    // except date field, change others
    let filteredInputs = initialState;
    if (typeof rawInputs === 'object' && !_.isEmpty(rawInputs)) {
      for (const field in rawInputs) {
        if (
          !(rawInputs[field].values === null || rawInputs[field].values === undefined) ||
          field === 'diabetes_onset'
        ) {
          filteredInputs[field].values = rawInputs[field].values;
        }
      }
    }

    return filteredInputs;
  };

  const handleChange = (event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: { values: event.target.value },
    }));
  };

  const handleReset = (event) => {
    if (props.medHistory) {
      const newValues = filterInputsNullToEmptyString(props.medHistory);
      setValues(newValues);
    }
    setIsHistoryDisabled(true);
  };

  const handleDateChange = (date) => {
    setValues({ ...values, diabetes_onset: { values: date } });
  };

  const formatDate = (date) => {
    if (typeof date?.getMonth !== 'function') return null;
    return convertToCustomDateString(date, 'yyyy-MM-dd');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setShowButtonLoading(true);
    try {
      let medicalHistory = { ...values };
      medicalHistory = filterInputs(medicalHistory);
      if (medicalHistory.diabetes_onset) medicalHistory['diabetes_onset'] = formatDate(medicalHistory.diabetes_onset);
      await props.updatePatientMedicalHistory(props.patientDetails.id, medicalHistory);
    } catch (e) {
      setShowButtonLoading(false);
    } finally {
      setShowButtonLoading(false);
      setIsHistoryDisabled(true);
    }
  };

  const handleEdit = () => {
    setIsHistoryDisabled(false);
  };

  return (
    <Root autoComplete="off" onSubmit={handleSubmit} disallowEdit={props.disallowEdit}>
      <Grid container alignItems="center" justifyContent="space-between" mb={2}>
        <Grid item>
          <IconHeading title={'Medical History'} icon={<HeartIcon />} />
        </Grid>
        <Grid>
          {props.disallowEdit ? null : !isHistoryDisabled ? (
            <Grid container item display="flex" gap={1}>
              <Grid item>
                <SmallButton startIcon={<FileTextIcon />} onClick={handleSubmit} showLoading={showButtonLoading}>
                  {staticWords.Save}
                </SmallButton>
              </Grid>
              <Grid item>
                <SmallButton startIcon={<XIcon />} onClick={handleReset} showLoading={showButtonLoading}>
                  {staticWords.Cancel}
                </SmallButton>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <SmallButton startIcon={<EditIcon />} onClick={handleEdit} showLoading={showButtonLoading}>
                {staticWords.Edit_Data}
              </SmallButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={props.disallowEdit ? 2 : 3} justifyContent="center">
        <Grid item xs={6}>
          <FormControl
            className={classes.formControl}
            fullWidth
            disabled={props.disabled || isHistoryDisabled}
            data-testid="select-diabetes-type"
          >
            <InputLabel id="select-diabetes-type-label">{staticWords.Diabetes_Type}</InputLabel>
            <Select
              defaultValue=""
              labelId="select-diabetes-type-label"
              id="select-diabetes-type"
              name="diabetes_type"
              value={values.diabetes_type?.values}
              onChange={handleChange}
              disableUnderline={props.disallowEdit}
              IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
              renderValue={(value) => {
                if (value === '-' || value === DIABETES_TYPE_UNKNOWN)
                  return props.disallowEdit ? '-' : staticWords.select___;
                switch (value) {
                  case NO_DIABETES:
                    return staticWords.No_diabetes;
                  case TYPE_1_DIABETES:
                    return staticWords.Type_1_Diabetes;
                  case TYPE_2_DIABETES:
                    return staticWords.Type_2_Diabetes;
                  case GESTATIONAL_DIABETES:
                    return staticWords.Gestational_Diabetes;
                  // case DIABETES_TYPE_UNKNOWN : return 'Unknown'
                }
              }}
            >
              <MenuItem value={DIABETES_TYPE_UNKNOWN} style={{ display: 'none' }}>
                <em>{staticWords.Unknown}</em>
              </MenuItem>
              {[
                { optionKey: NO_DIABETES, optionValue: staticWords.No_diabetes },
                {
                  optionKey: TYPE_1_DIABETES,
                  optionValue: staticWords.Type_1_Diabetes,
                },
                {
                  optionKey: TYPE_2_DIABETES,
                  optionValue: staticWords.Type_2_Diabetes,
                },
                {
                  optionKey: GESTATIONAL_DIABETES,
                  optionValue: staticWords.Gestational_Diabetes,
                },
              ].map((item) => {
                return (
                  <MenuItem key={item.optionKey} value={item.optionKey}>
                    {item.optionValue}
                  </MenuItem>
                );
              })}

              {/* <MenuItem value={NO_DIABETES}>No diabetes</MenuItem>
                <MenuItem value={TYPE_1_DIABETES}>Type 1 Diabetes</MenuItem>
                <MenuItem value={TYPE_2_DIABETES}>Type 2 Diabetes</MenuItem>
                <MenuItem value={GESTATIONAL_DIABETES}>
                  Gestational Diabetes
                </MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={datePickerLangText(i18n.language)}>
            <DesktopDatePicker
              renderInput={(textFieldProps) => {
                textFieldProps.inputProps.value = props.disallowEdit
                  ? textFieldProps.inputProps.value || '-'
                  : textFieldProps.inputProps.value;
                return <TextField {...textFieldProps} sx={{ width: '100%' }} />;
              }}
              OpenPickerButtonProps={{
                style: { display: props.disallowEdit && 'none' },
              }}
              fullWidth
              margin="none"
              variant="inline"
              className={classes.datePicker.values}
              disableFuture
              inputFormat="dd/MM/yyyy"
              label={staticWords.Diabetes_Onset}
              value={values.diabetes_onset.values}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change diabetes onset',
              }}
              helperText={staticWords.When_were_you_first_diagnosed_with_diabetes_}
              disabled={props.disabled || isHistoryDisabled}
              autoOk
              InputProps={{ disableUnderline: props.disallowEdit && true }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl} fullWidth disabled={props.disabled || isHistoryDisabled}>
            <InputLabel id="select-eye-surgery-history-label">{staticWords.Eye_Surgery_History}</InputLabel>
            <Select
              defaultValue=""
              labelId="select-eye-surgery-history-label"
              name="eye_surgery_history"
              id="eye-surgery-history"
              value={values.eye_surgery_history.values}
              onChange={handleChange}
              disableUnderline={props.disallowEdit}
              IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
              renderValue={(value) => {
                if (value === '-') return props.disallowEdit ? value : staticWords.select___;
                return value ? staticWords.Had_eye_surgery : staticWords.Never_had_eye_surgery;
              }}
            >
              <MenuItem value="-" style={{ display: 'none' }}>
                -
              </MenuItem>
              <MenuItem value={false}>{staticWords.Never_had_eye_surgery}</MenuItem>
              <MenuItem value={true}>{staticWords.Had_eye_surgery}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl} fullWidth disabled={props.disabled || isHistoryDisabled}>
            <InputLabel id="select-heart-disease-label">{staticWords.Heart_Disease}</InputLabel>
            <Select
              defaultValue=""
              labelId="select-heart-disease-label"
              name="heart_disease"
              id="heart-disease-history"
              value={values?.heart_disease?.values}
              onChange={handleChange}
              disableUnderline={props.disallowEdit}
              IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
              renderValue={(value) => {
                if (value === '-') return props.disallowEdit ? value : staticWords.select___;
                return value ? staticWords.Has_heart_disease : staticWords.No_heart_disease;
              }}
            >
              <MenuItem value="-" style={{ display: 'none' }}>
                -
              </MenuItem>
              <MenuItem value={false}>{staticWords.No_heart_disease}</MenuItem>
              <MenuItem value={true}>{staticWords.Has_heart_disease}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl} fullWidth disabled={props.disabled || isHistoryDisabled}>
            <InputLabel id="select-high-blood-pressure-label">{staticWords.High_Blood_Pressure}</InputLabel>
            <Select
              defaultValue=""
              labelId="select-high-blood-pressure-label"
              name="high_blood_pressure"
              id="high-blood-pressure-history"
              value={values.high_blood_pressure.values}
              onChange={handleChange}
              disableUnderline={props.disallowEdit}
              IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
              renderValue={(value) => {
                if (value === '-') return props.disallowEdit ? value : staticWords.select___;
                return value ? staticWords.Yes : staticWords.No;
              }}
            >
              <MenuItem value="-" style={{ display: 'none' }}>
                -
              </MenuItem>
              <MenuItem value={true}>{staticWords.Yes}</MenuItem>
              <MenuItem value={false}>{staticWords.No}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl} fullWidth disabled={props.disabled || isHistoryDisabled}>
            <InputLabel id="select-high-cholesterol-label">{staticWords.High_Cholesterol}</InputLabel>
            <Select
              defaultValue=""
              labelId="select-high-cholesterol-label"
              name="high_cholesterol"
              id="high-cholesterol-history"
              value={values.high_cholesterol.values}
              onChange={handleChange}
              disableUnderline={props.disallowEdit}
              IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
              renderValue={(value) => {
                if (value === '-') return props.disallowEdit ? value : staticWords.select___;
                return value ? staticWords.Yes : staticWords.No;
              }}
            >
              <MenuItem value="-" style={{ display: 'none' }}>
                -
              </MenuItem>
              <MenuItem value={true}>{staticWords.Yes}</MenuItem>
              <MenuItem value={false}>{staticWords.No}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl} fullWidth disabled={props.disabled || isHistoryDisabled}>
            <InputLabel id="select-dr-presence-label">{staticWords.DR_Presence}</InputLabel>
            <Select
              defaultValue=""
              labelId="select-dr-presence-label"
              name="dr_presence"
              id="dr-presence-history"
              value={values.dr_presence.values}
              onChange={handleChange}
              disableUnderline={props.disallowEdit}
              IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
              renderValue={(value) => {
                if (value === '-') return props.disallowEdit ? value : staticWords.select___;
                switch (value) {
                  case NO_DISEASE:
                    return staticWords.No_Disease;
                  case MILD:
                    return staticWords.Mild_Retinopathy;
                  case MODERATE:
                    return staticWords.Moderate_Retinopathy;
                  case SEVERE:
                    return staticWords.Severe_Retinopathy;
                  case PDR:
                    return staticWords.Proliferative_Retinopathy;
                }
              }}
            >
              <MenuItem value="-" style={{ display: 'none' }}>
                -
              </MenuItem>
              <MenuItem value={NO_DISEASE}>{staticWords.No_Disease}</MenuItem>
              <MenuItem value={MILD}>{staticWords.Mild_Retinopathy}</MenuItem>
              <MenuItem value={MODERATE}>{staticWords.Moderate_Retinopathy}</MenuItem>
              <MenuItem value={SEVERE}>{staticWords.Severe_Retinopathy}</MenuItem>
              <MenuItem value={PDR}>{staticWords.Proliferative_Retinopathy}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl} fullWidth disabled={props.disabled || isHistoryDisabled}>
            <InputLabel id="select-macular-edema-presence-label">{staticWords.Macular_Edema_Presence}</InputLabel>
            <Select
              defaultValue=""
              labelId="select-macular-edema-presence-label"
              name="me_presence"
              id="macular-edema-presence-history"
              value={values.me_presence.values}
              onChange={handleChange}
              disableUnderline={props.disallowEdit}
              IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
              renderValue={(value) => {
                if (value === '-') return props.disallowEdit ? value : staticWords.select___;
                else if (value === ABSENT) return staticWords.Absent;
                else if (value === PRESENT) return staticWords.Present;
              }}
            >
              <MenuItem value="-" style={{ display: 'none' }}>
                -
              </MenuItem>
              <MenuItem value={ABSENT}>{staticWords.Absent}</MenuItem>
              <MenuItem value={PRESENT}>{staticWords.Present}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Root>
  );
};

MedicalHistory.propTypes = {
  medHistory: PropTypes.object,
  patientDetails: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  patientDetails: state.patients.patientDetails,
  medHistory: state.healthData.medHistory,
});

export default connect(mapStateToProps, { updatePatientMedicalHistory })(MedicalHistory);
