// QA Results
export const QA_RESULT_ENUMS = {
  NO_RESULT: 1,
  PASSED: 2,
  FAILED: 3,
};

// LR Results
export const LR_RESULT_ENUMS = {
  NO_RESULT: 1,
  LEFT: 2,
  RIGHT: 3,
};

// General Results
export const GENERAL_RESULT_ENUMS = {
  NOT_APPLICABLE: -1,
  NO_RESULT: 1,
  UNABLE_TO_GRADE: 2,
  NON_REFERABLE: 3,
  REFERABLE: 4,
  NORMAL: 20, // only used by reviewers
};

export const DR_LEVEL_ENUMS = {
  NO_RESULT: 1,
  UNABLE_TO_GRADE: 2,
  NORMAL: 3,
  MILD: 4,
  MODERATE: 5,
  SEVERE: 6,
  PDR: 7,
};

export const DME_LEVEL_ENUMS = {
  NO_RESULT: 1,
  UNABLE_TO_GRADE: 2,
  NORMAL: 3,
  SUSPECT: 4, // deprecated
  MILD: 5,
  MODERATE: 6,
  SEVERE: 7,
};

export const MEDIA_OPACITY_RESULT_ENUMS = {
  NO_RESULT: 1,
  UNABLE_TO_GRADE: 2,
  NORMAL: 3,
  NON_SIGNIFICANT: 4,
  SIGNIFICANT: 5,
};
// --------------- STRING ---------------------
// General Results String
export const REF_REPORT_STR = 'REFER TO REPORT';
export const GRADING_STR = 'AI Grading...';
//  Error Results string
export const GENERAL_RESULT_STR_ENUMS = {
  [GENERAL_RESULT_ENUMS.NOT_APPLICABLE]: 'Not Applicable',
  [GENERAL_RESULT_ENUMS.NO_RESULT]: 'No Result',
  [GENERAL_RESULT_ENUMS.UNABLE_TO_GRADE]: 'Unable to Grade',
  [GENERAL_RESULT_ENUMS.NON_REFERABLE]: 'Non-Referable',
  [GENERAL_RESULT_ENUMS.REFERABLE]: 'Referable',
  [GENERAL_RESULT_ENUMS.NORMAL]: 'Normal', // only used by reviewers
};

export const CDR_ABNORMAL_THRESHOLD = 0.7;

export const SERVICE_GRADING_STATUS = {
  RESULT_PENDING: 1,
  RESULT_ASSIGNED: 2,
  RESULT_COMPLETED: 3,
};

// ----------------- URGENCY LEVEL ---------------
export const URGENCY_LEVEL_ENUMS = {
  NOT_URGENT: 0,
  URGENT: 1,
  RESOLVED: 2,
};

export const URGENCY_LEVEL_STR_ENUMS = {
  [URGENCY_LEVEL_ENUMS.NOT_URGENT]: 'Not Urgent',
  [URGENCY_LEVEL_ENUMS.URGENT]: 'Urgent',
  [URGENCY_LEVEL_ENUMS.RESOLVED]: 'Resolved',
};
// ------------------------ STRING -------------------

export const DME_LEVEL_STR_ENUMS = {
  [null]: 'No Result',
  [DR_LEVEL_ENUMS.UNABLE_TO_GRADE]: 'Unable to Grade',
  [DME_LEVEL_ENUMS.NORMAL]: 'Normal',
  [DME_LEVEL_ENUMS.SUSPECT]: 'Suspected',
  [DME_LEVEL_ENUMS.MILD]: 'Mild',
  [DME_LEVEL_ENUMS.MODERATE]: 'Moderate',
  [DME_LEVEL_ENUMS.SEVERE]: 'Severe',
};

export const MEDIA_OPACITY_RESULT_STR_ENUMS = {
  [null]: 'No Result',
  [MEDIA_OPACITY_RESULT_ENUMS.UNABLE_TO_GRADE]: 'Unable to Grade',
  [MEDIA_OPACITY_RESULT_ENUMS.NORMAL]: 'Normal',
  [MEDIA_OPACITY_RESULT_ENUMS.NON_SIGNIFICANT]: 'Non-Significant',
  [MEDIA_OPACITY_RESULT_ENUMS.SIGNIFICANT]: 'Significant',
};

export const DR_FULL_RESULT_STR_ENUMS = {
  [DR_LEVEL_ENUMS.NO_RESULT]: 'No Result',
  [DR_LEVEL_ENUMS.UNABLE_TO_GRADE]: 'Unable to Grade',
  [DR_LEVEL_ENUMS.NORMAL]: 'Normal',
  [DR_LEVEL_ENUMS.MILD]: 'Mild NPDR',
  [DR_LEVEL_ENUMS.MODERATE]: 'Moderate NPDR',
  [DR_LEVEL_ENUMS.SEVERE]: 'Severe NPDR',
  [DR_LEVEL_ENUMS.PDR]: 'PDR',
};

export const LR_STR_ENUMS = {
  [LR_RESULT_ENUMS.LEFT]: 'Left',
  [LR_RESULT_ENUMS.RIGHT]: 'Right',
};
export const STR_LR_ENUMS = {
  left: LR_RESULT_ENUMS.LEFT,
  right: LR_RESULT_ENUMS.RIGHT,
  Left: LR_RESULT_ENUMS.LEFT,
  Right: LR_RESULT_ENUMS.RIGHT,
  LEFT: LR_RESULT_ENUMS.LEFT,
  RIGHT: LR_RESULT_ENUMS.RIGHT,
  leftEyeResult: LR_RESULT_ENUMS.LEFT,
  rightEyeResult: LR_RESULT_ENUMS.RIGHT,
};

// ----------------- Deprecated ---------------
//AMD and AMD DR Results
export const DR_DISEASE = 3;
export const AMD_DR_DISEASE = 5;
export const AMD_HP = 98;
export const AMD_DR_HP = 99;
export const AMD_DR_DISEASE_STR = 'Suspect DR Disease';
export const AMD_DISEASE_STR = 'Suspect AMD Disease';
export const AMD_HP_STR = 'Suspect AMD disease (High probability AMD, some AMD signs)';
export const AMD_DR_HP_STR = 'Suspect DR disease (High probability, some DR Signs)';
export const CLICK_TO_UPDATE_STR = 'Click to update';
// ----------------- Deprecated ---------------
